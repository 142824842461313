import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { db } from '../firebase/firebase';
import { AuthContext, AuthConsumer } from '../firebase/authContext';
import AdminTabs from '../components/AdminTabs';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FaImage, FaRegTrashAlt } from "react-icons/fa";
import { FiSave } from 'react-icons/fi';
import firebase from '../firebase/firebase';
import ProgressIndicator from '../components/ProgressIndicator'
import Switch from 'react-switch';
import '../css/custom.css'
import { CompactPicker } from 'react-color';
import podium from '../img/printpodium.svg';
import SVG from 'react-inlinesvg';
import NanoId from 'nanoid';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  textField: {
    margin: theme.spacing.unit,
    maxWidth: 350,
    width: 350
  },
  inputLabel:{
    padding: '14px 12px'
  },
  input: {
    opacity: 0,
    position: 'absolute',
    pointerEvents: 'none'
    },
    button: {
      margin: theme.spacing.unit,
      marginLeft: '0px',
      padding: '0px',
      pointer: 'cursor'
    },
    logoWrapper: {
      display: 'inline-block',
      textAlign: 'center', 
      width: 150, 
      height: 110,
      border: '2px dashed grey',
      overflow: 'hidden'
    }
});

class AdminProfile extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      file: null,
      logoUrl: null,
      logoFileName: null,
      isLoading: true,
      sortingDefaultMode: true,
      podiumSecondColor: '#FFCE54',
      podiumFirstColor: '#FC6E51',
      podiumThirdColor: '#FFCE54',
      podiumKey: NanoId()
    }

    this.handleUpload = this.handleUpload.bind(this);
    this.handleSortingDefaultModeChange = this.handleSortingDefaultModeChange.bind(this);
  }


componentDidMount() {

  var points = [];
  var self = this;
  let customer = this.context.customer;

  db.collection("customers").doc(customer.id).collection('events').get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      var pointObj = {
        id: null,
        eventId: doc.id,
        eventName: doc.data().name,
        points: null,
        placementpoints: null,
        unit: doc.data().unit
      }
      points.push(pointObj);
    });

    var storageRef = firebase.storage().ref();

    if(customer.logoFileName){
      var logoRef = storageRef.child('images/' + customer.id + '/' + customer.logoFileName)
      logoRef.getDownloadURL().then(function(url) {
        self.setState({
          checkedPoints: points,
          points: points,
          logoUrl: url,
          logoFileName: customer.logoFileName,
          zoomIn: true,
          isLoading: false,
          sortingDefaultMode: customer.sortingDefaultMode,
          podiumSecondColor: customer.podiumSecondColor,
          podiumFirstColor: customer.podiumFirstColor,
          podiumThirdColor: customer.podiumThirdColor
        });
      });
    } else {
      self.setState({
        checkedPoints: points,
        points: points,
        zoomIn: true,
        isLoading: false,
        sortingDefaultMode: customer.sortingDefaultMode,
        podiumSecondColor: customer.podiumSecondColor,
        podiumFirstColor: customer.podiumFirstColor,
        podiumThirdColor: customer.podiumThirdColor
      });
    }
});

}

handleUpload = e => {
  
  if(e.target.files[0]) {
    var self = this;
    this.setState({ 
     file: e.target.files[0],
     isLoading: true
    }, function() {
      self.handleDeleteLogoFromStorage().then(function() {
        self.uploadLogo();
      }).catch(function(error) {
        self.uploadLogo();
      });
    });
  }
}

handleDeleteLogo = e => {

  let customer = this.context.customer;
  this.setState({ 
    isLoading: true
  });
  var self = this;
  this.handleDeleteLogoFromStorage().then(function() {
    self.setLogoFileName(null).then(function() {
      customer.logoFileName = null;
      self.setState({ 
        logoUrl: null,
        logoFileName: null,
        isLoading: false
      });
   }).catch(function(error) {
     console.error("Error writing document: ", error);
   });
  }).catch(function(error) {
    self.setLogoFileName(null).then(function() {
      customer.logoFileName = null;
      self.setState({ 
       logoUrl: null,
       logoFileName: null,
       isLoading: false
     });
   }).catch(function(error) {
    this.setState({ 
      isLoading: false
    });
   });
  });
}

handleSortingDefaultModeChange(checked) {

  let customer = this.context.customer;
  var custRef =  db.collection("customers").doc(customer.id);
 
  custRef.set({ 
    sortingDefaultMode: checked 
  }, { merge: true });

  customer.sortingDefaultMode = checked;
  this.setState({ sortingDefaultMode: checked });
}

handleDeleteLogoFromStorage = async () => {

  const { logoUrl, logoFileName } = this.state;
 
  if(logoUrl === null){    
    let promise = new Promise((resolve, reject) => {
      resolve('Ok, nothing do delete');
    });
    return(promise);
  }

  let customer = this.context.customer;

  var storageRef = firebase.storage().ref();

  var logoRef = storageRef.child('images/' + customer.id + '/' + logoFileName);

  return logoRef.delete();

} 

uploadLogo = e => {

  const { file } = this.state;
  
  if(file === null)
  return;

  var self = this;
 var metadata = {
   contentType: file.type
 };

 let customer = this.context.customer;

 var fileExtension = file.name.split('.').pop();
 var fileName = 'logo.' + fileExtension;

 var storageRef = firebase.storage().ref();

 var uploadTask = storageRef.child('images/' + customer.id + '/' + fileName).put(file, metadata);

 uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
 
   function(snapshot) {
   // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
   //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  // console.log('Upload is ' + progress + '% done');
   switch (snapshot.state) {
     case firebase.storage.TaskState.PAUSED: // or 'paused'
    //   console.log('Upload is paused');
       break;
     case firebase.storage.TaskState.RUNNING: // or 'running'
    //   console.log('Upload is running');
       break;
       default:
       break;
   }
 }, function(error) {

 // A full list of error codes is available at
 // https://firebase.google.com/docs/storage/web/handle-errors
 switch (error.code) {
   case 'storage/unauthorized':
     // User doesn't have permission to access the object
     break;

   case 'storage/canceled':
     // User canceled the upload
     break;

   case 'storage/unknown':
     // Unknown error occurred, inspect error.serverResponse
     break;

     default:
       break;
 }
}, function() {
 // Upload completed successfully, now we can get the download URL
 uploadTask.snapshot.ref.getDownloadURL().then(function(logoUrl) {

  self.setLogoFileName(fileName).then(function() {
    customer.logoFileName = fileName;
    self.setState({ 
      logoUrl: logoUrl,
      logoFileName: fileName,
      isLoading: false,
    });
  }).catch(function(error) {
    self.setState({ 
      isLoading: false
    });
  });
 });
});
}

setLogoFileName = async fileName => {

 let customer = this.context.customer;

 var custRef =  db.collection("customers").doc(customer.id);

 return custRef.set({ logoFileName: fileName }, { merge: true });

}

handlePodiumColorChange = (color, name) => {
   this.setState({ 
    [name]: color.hex,
    podiumKey: NanoId()
  });

  let customer = this.context.customer;

  var custRef =  db.collection("customers").doc(customer.id);
 
  custRef.set({ [name]: color.hex }, { merge: true });

  customer[name] = color.hex;

};

static contextType = AuthContext;
  render() {

    const { classes } = this.props;
    const { logoUrl, sortingDefaultMode, isLoading, podiumSecondColor, podiumFirstColor, podiumThirdColor, podiumKey } = this.state;

    if(isLoading === false){
  return (
    <React.Fragment>
        <AdminTabs activeTabIndex={1}/>
        <AuthConsumer>
      {({ customer, authUser, logout }) => (
        <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          {customer.name}
        </Typography>
        <Typography component="p">
          Orgnr: {customer.orgno}
        </Typography>

        <Typography component="p" style={{marginTop: 10, fontWeight: 'bold'}}>
          Logotype: 
        </Typography>

        <form id="form-file-upload" style={{outline: 'none'}}>
<input
accept="image/*"
className={classes.input}
id="input-file-upload"
type="file"
onChange={this.handleUpload}
/>
<Button variant="outlined" component="span" className={classes.button} style={{width: 154}}>
<label htmlFor="input-file-upload" className={classes.inputLabel} style={{padding: 8}}>
<FiSave size={20} style={{verticalAlign: 'top'}}/>&nbsp;
<span style={{fontSize: 12}}> Välj bild</span>
</label>
</Button>

</form>


<div className={classes.logoWrapper} style={{verticalAlign: 'top'}}>

{logoUrl ? <img src={logoUrl} alt="Logo" style={{maxWidth: '100%', height: 'auto'}}/> : 
<React.Fragment>
<FaImage style={{ fontSize: 30, color: '#C8C8C8', marginTop: 25 }}  />
<Typography variant="body2" style={{color: '#C8C8C8'}}>
  <span style={{fontSize: 12}}>Ingen logo</span>
</Typography>
</React.Fragment>
}
</div>

<div>
<Button variant="outlined" component="span" className={classes.button} onClick={this.handleDeleteLogo} style={{width: 154}}>
<label className={classes.inputLabel} style={{padding: 8}}>
<FaRegTrashAlt size={20} style={{verticalAlign: 'top', color: 'red'}}/>&nbsp;
  <span style={{fontSize: 12}}>Radera logo</span>
</label>
</Button>
</div>

<Typography component="p" style={{marginTop: 10, fontWeight: 'bold'}}>
          Standardläge för automatisk sortering av spelare: 
</Typography>

<Switch
    checked={sortingDefaultMode}
    onChange={this.handleSortingDefaultModeChange}
    handleDiameter={12}
    offColor="#787878"
    onColor="#509CF5"
    offHandleColor="#fff"
    onHandleColor="#fff"
    height={20}
    width={37}
    uncheckedIcon={false}
    checkedIcon={false}
    className="react-switch"
    id="material-switch"
  />
  <Typography style={{display: 'inline-block', marginLeft: 7}}>{sortingDefaultMode ? 'På' : 'Av'}</Typography>

<br/><br/>
<Typography component="p" style={{marginTop: 10, fontWeight: 'bold'}}>
          Färgschema för prispall:
</Typography>


<SVG
key={podiumKey}
style={{width: '250px'}}
  description="The React logo"
  onError={error => console.log(error.message)} 
  preProcessor={code => {
    code = code.replace('podiumSecondColor', podiumSecondColor);
    code = code.replace('podiumFirstColor', podiumFirstColor);
    code = code.replace('podiumThirdColor', podiumThirdColor);
    return code;
  }}
  src={podium}
/>

<br/>

<div style={{display: 'inline-block', marginRight: 10}}>
<Typography component="p" style={{fontWeight: 'bold'}}>
         Plats 2:
</Typography>
  <CompactPicker 
  color={this.state.podiumSecondColor}
  onChangeComplete={(e) => this.handlePodiumColorChange(e, 'podiumSecondColor') }/>
</div>

<div style={{display: 'inline-block', marginRight: 10}}>
<Typography component="p" style={{fontWeight: 'bold'}}>
         Plats 1:
</Typography>
    <CompactPicker 
    color={this.state.podiumFirstColor}
  onChangeComplete={(e) => this.handlePodiumColorChange(e, 'podiumFirstColor') }/>
  </div>

  <div style={{display: 'inline-block', marginRight: 10}}>
  <Typography component="p" style={{fontWeight: 'bold'}}>
         Plats 3:
</Typography>
    <CompactPicker 
    color={this.state.podiumThirdColor}
  onChangeComplete={(e) => this.handlePodiumColorChange(e, 'podiumThirdColor') }/>
</div>

<Typography component="p" style={{marginTop: 20}}>
          Inloggad med e-postadressen: {authUser.email}
        </Typography>

        <Button style={{marginTop: 10}} variant="outlined" onClick={logout} color="primary">
        Klicka här för att logga ut
          </Button>
      </Paper>
      </div>
      )}
      </AuthConsumer>

      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </React.Fragment>
  );
}
return (<ProgressIndicator/>)
}
}

export default withStyles(styles)(AdminProfile);
