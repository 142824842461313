import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { FaRegUserCircle, FaBasketballBall } from "react-icons/fa";
import { withRouter } from 'react-router-dom';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

class AdminTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {

    switch (value) {
      case 0:
      this.props.history.push({pathname: '/admin/events'});
      break;
      case 1:
      this.props.history.push({pathname: '/admin/profile'});
      break;
      default:
      break;
    }
  } 

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.props.activeTabIndex}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Grenar" icon={<FaBasketballBall style={{ fontSize: 20 }} />} />
            <Tab label="Profil" icon={<FaRegUserCircle style={{ fontSize: 20 }}/>} />
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

AdminTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AdminTabs));
