import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { db } from '../firebase/firebase';
import { AuthContext } from '../firebase/authContext';
import ProgressIndicator from '../components/ProgressIndicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from '../components/EnhancedTableHead';
import { getSorting, stableSort } from '../common/helpers';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Routes from '../constants/routes';

const rows = [

  { id: 'name', numeric: false, disablePadding: true, label: 'Sällskap' },
  { id: 'otherInfo', numeric: false, disablePadding: true, label: 'Övrig information' },
  { id: 'resume', numeric: false, disablePadding: true, label: '' },
  { id: 'edit', numeric: false, disablePadding: true, label: '' },
  { id: 'created', numeric: true, disablePadding: true, label: 'Skapat' },

];

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: 10,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    marginBottom: 15
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    margin: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  tableCell: { padding: '1px' }
});

class AdminTeams extends React.Component {

  constructor() {
    super()

    this.state = {
      order: 'desc',
      orderBy: 'created',
      teams: null,
      page: 0,
      rowsPerPage: 10,
      newEvent: {
        name: "",
        created: null
      }
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    this.fetchTeams();
  }

  componentWillMount() {
    document.body.classList.remove("bgTexture");
  }

  fetchTeams() {

    var teams = [];

    let customerId = this.context.customer.id;

    db.collection("customers").doc(customerId).collection('games').get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {

        var name = "";
        doc.data().teams.forEach((team, index) => {
          if ((index + 1) === doc.data().teams.length) {
            name += team.name;
          } else {
            name += team.name + ', ';
          }
        });

        var teamObj = {
          id: doc.id,
          otherInfo: doc.data().otherInfo,
          name: name,
          created: doc.data().created.toDate()
        }
        teams.push(teamObj);
      });

      this.setState({
        teams: teams
      });
    });
  }

  deleteGame(id) {

    let customerId = this.context.customer.id;

    db.collection("customers").doc(customerId).collection('games').doc(id).delete().then(() => {
      console.log("Document successfully deleted!");
      //this.fetchTeams();
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });

  }

  static contextType = AuthContext;
  render() {

    const { classes } = this.props;
    const { teams, order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, (teams === null ? -1 : teams.length) - page * rowsPerPage);
    if (teams !== null) {
      return (
        <React.Fragment>

          <Paper className={classes.root}>

            <div style={{ display: 'flex' }}>
              <Typography variant="h6" gutterBottom>
                Sällskap
              </Typography>
              <Typography variant="h6" gutterBottom style={{ textAlign: 'right' }} className={classes.grow}>
              </Typography>

            </div>

            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={teams.length}
                  rows={rows}
                />
                <TableBody>
                  {stableSort(teams, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={n.id}
                        >
                          <TableCell component="th" scope="row" className={classes.tableCell}>
                            {n.name}
                          </TableCell>

                          <TableCell component="th" scope="row" style={{whiteSpace: 'pre-line'}} className={classes.tableCell}>
                            {n.otherInfo && <div>{n.otherInfo}</div>}
                          </TableCell>

                          <TableCell component="th" scope="row" className={classes.tableCell}>
                            <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer} onClick={() => this.props.history.push(Routes.PLAY + "/" + n.id)}>Återuppta</Button>
                          </TableCell>

                          <TableCell component="th" scope="row" className={classes.tableCell}>
                            <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer} onClick={() => this.props.history.push('/admin/' + n.id + '/edit')}>Redigera</Button>
                          </TableCell>

                          <TableCell align="right" className={classes.tableCell}>{Moment(n.created).format('YYYY-MM-DD HH:mm:ss')}
                          {/* <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer} onClick={() => this.deleteGame(n.id)}>Ta bort</Button> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={teams.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>



        </React.Fragment>
      );
    }
    return (<ProgressIndicator />)
  }
}

export default withStyles(styles)(AdminTeams);
