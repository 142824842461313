import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { AuthProvider } from './firebase/authContext';
//Test
ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>
, document.getElementById('root'));
registerServiceWorker();
