import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import firebase from '../firebase/firebase';
import * as constants from "../common/constants";

const styles = theme => ({

  button: {
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  cancelButton: {
    color: '#509CF5'
  },
  formControl: {
    margin: '5px 5px 5px 0px'
  },
  myComponent: {
    "& .MuiIconButton-root": {
      padding: 0
    }
  }
});

class CreateEventDialog extends React.Component {

  constructor() {
    super()

    this.state = {
      closestRadioIsDisabled: false,
      event: {
        name: '',
        calculationCategory: 'highest',
        calculationFormat: constants.calculationFormats.point.value,
        unit: 'p',
        placementCalculation: constants.placementCalculation.default.value,
        roundPoints: []
      }
    }
  }

  handleChange = name => event => {
    let _event = Object.assign({}, this.state.event);
    //Closest-radioknappen ska vara inaktiverad om användaren väljer tid som format.
    if(name === "calculationFormat") {
      if(event.target.value === constants.calculationFormats.time.value) {
        _event[name] = event.target.value;
        _event["calculationCategory"] = this.state.event.calculationCategory === 'closest' ? 'highest' : this.state.event.calculationCategory;
      this.setState({
        event: _event,
        closestRadioIsDisabled: true,
      });
    } else {
       _event[name] = event.target.value;
      this.setState({
        event: _event,
        closestRadioIsDisabled: false
      });
    }
    return;
  }
  _event[name] = event.target.value;
    this.setState({
      event: _event,
    });
  };


  handleInputChange = (e) => {

    this.props.onInputChange(e.target.value);

  }

  handleClose = (e) => {

    this.props.onClose();

  }

  handleAdd = () => {

    let newEvent = Object.assign({}, this.state.event);

    if (newEvent.calculationCategory === constants.calculationCategories.closest.value) {
      newEvent.closestTo = 100;
    }

    newEvent.created = firebase.firestore.Timestamp.fromDate(new Date());
    newEvent.isDividingEvent = false;
    newEvent.roundPoints = [];

    this.props.onAdd(newEvent);

   let event = {
      name: '',
      calculationCategory: 'highest',
      calculationFormat: constants.calculationFormats.point.value,
      placementCalculation: constants.placementCalculation.default.value,
      roundPoints: []
    }

    this.setState({event: event});

  }

  handleKeyPress = (event) => {

    if (event.key === 'Enter') {

      var newEvent = Object.assign({}, this.state.event);
      newEvent.created = firebase.firestore.Timestamp.fromDate(new Date());
      newEvent.isDividingEvent = false;
      this.props.onAdd(newEvent);
    }
  }


  render() {

    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.dialogStatus}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{this.props.dialogTitle}</DialogTitle>
        <DialogContent>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={this.props.dialogLabel}
            type="text"
            fullWidth
            onChange={this.handleChange('name')}
            onKeyPress={this.handleKeyPress}
          />
          <br/>

          <DialogContentText style={{ marginTop: 30 }}>
          Poängformat
          </DialogContentText>

          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="calculationFormat"
              name="calculationFormat"
              className={classes.group}
              value={this.state.event.calculationFormat}
              onChange={this.handleChange('calculationFormat')}
            >
              <FormControlLabel value={constants.calculationFormats.point.value} control={<Radio style={{paddingTop: 0, paddingBottom: 0}}/>} label={constants.calculationFormats.point.description} />
              <FormControlLabel value={constants.calculationFormats.time.value} control={<Radio />} label={constants.calculationFormats.time.description} />
            </RadioGroup>
          </FormControl>

          <br/>


          <TextField
            margin="dense"
            id="name"
            label={"Enhet"}
            type="text"
            fullWidth
            defaultValue={"p"}
            onChange={this.handleChange('unit')}
            onKeyPress={this.handleKeyPress}
          />
          <br/>
          

          <DialogContentText style={{ marginTop: 30 }}>
          Beräkningsformat
          </DialogContentText>

          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="calculationCategory"
              name="calculationCategory"
              className={classes.group}
              value={this.state.event.calculationCategory}
              onChange={this.handleChange('calculationCategory')}
            >
              <FormControlLabel value={constants.calculationCategories.highest.value} control={<Radio style={{paddingTop: 0, paddingBottom: 0}}/>} label="Högst poäng ger bästa placering" />
              <FormControlLabel value={constants.calculationCategories.closest.value} control={<Radio disabled={this.state.closestRadioIsDisabled}/>} label="Närmast ett tal ger bästa placering (talet anges när spelet är igång)" />
              <FormControlLabel value={constants.calculationCategories.lowest.value} control={<Radio style={{paddingTop: 0, paddingBottom: 0}}/>} label="Lägst poäng ger bästa placering" />
            </RadioGroup>
          </FormControl>

          <DialogContentText style={{ marginTop: 30 }}>
          Placeringsberäkning
          </DialogContentText>

          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="placementCalculation"
              name="placementCalculation"
              className={classes.group}
              value={this.state.event.placementCalculation}
              onChange={this.handleChange('placementCalculation')}
            >
              <FormControlLabel value={constants.placementCalculation.default.value} control={<Radio style={{paddingTop: 0, paddingBottom: 0}}/>} label="Standard" />
              <FormControlLabel value={constants.placementCalculation.following.value} control={<Radio disabled={this.state.closestRadioIsDisabled}/>} label="Direkt efterföljande (för grenar som endast tävlas i grupp)" />
            </RadioGroup>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={() => this.handleClose()}>
            Avbryt
          </Button>
          <Button className={classes.button} onClick={() => this.handleAdd()}>
            {this.props.addLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CreateEventDialog);