export const PLAY = '/play';
export const PRINT = '/print';
export const CREATE_GAME = '/createGame';
export const PUBLIC = '/public'
export const PROTECTED = '/protected'
export const START = '/'
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN_TEAMS = '/activeteams'
export const ADMIN_EVENTS = '/admin/events'
export const ADMIN_PROFILE = '/admin/profile'