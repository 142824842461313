import React from 'react';
import { auth } from './firebase';
import { doSignOut } from './auth'
import { db } from '../firebase/firebase';

const AuthContext = React.createContext(null);

class AuthProvider extends React.Component {

  constructor() {
    super()
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)

    this.state = { 
      isLoading: true, 
      customer: null,
      authUser: null 
     }
  }
  
  login() {
  }
  
  logout() {
    doSignOut();
  }

componentDidMount(){
let self = this;
  this.listener = auth.onAuthStateChanged(
    authUser => {
       if(authUser !== null){
        var usersRef = db.collection("users");
        
        var customer = null;
  
        usersRef.where("email", "==", authUser.email).limit(1).get().then(function (querySnapshot) {

         var user = querySnapshot.docs[0];
         if(typeof user !== 'undefined'){

          let customerId = user.data().customerId;
        
          db.collection("customers").doc(customerId).get().then(function(doc) {
            if (doc.exists) {
              customer = doc.data();
              customer.id = doc.id;
              self.setState({ 
                authUser: authUser,
                customer: customer,
                isLoading: false });
          } else {
            self.setState({ isLoading: false, authUser: null, customer: null });
          }
          });
         }
      });
    }
    else {
      self.setState({ isLoading: false, authUser: null, customer: null });
    }
  }
  );
}

componentWillUnmount() {
  this.listener();
}

    render() {
      return (
        <AuthContext.Provider
        value={{
          authUser: this.state.authUser,
          customer: this.state.customer,
          isLoading: this.state.isLoading,
          login: this.login,
          logout: this.logout
        }}
      >
          {this.props.children}
        </AuthContext.Provider>
      )
    }
  }
  const AuthConsumer = AuthContext.Consumer;
  export { AuthProvider, AuthContext, AuthConsumer }