import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ProgressIndicator from './ProgressIndicator'
import Button from '@material-ui/core/Button';
import { Flipper, Flipped } from 'react-flip-toolkit';
import  { placementIsShown } from '../common/helpers';
import { MdStars } from "react-icons/md";
import * as constants from "../common/constants";
import { renderPoints } from '../common/helpers';

const styles = theme => ({
  table: {
    minWidth: 700
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    top: 0,
    paddingBottom: 20,
    zIndex: 10,
    minWidth: '100px',
    textAlign:'center',
    padding: '4px 10px 4px 10px',
    color: '#000'
  },
  topLeftCell: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    paddingTop: 23,
    paddingBottom: 20,
    paddingLeft: 13,
    top: 0,
    left: 0,
    zIndex: 11,
    whiteSpace: 'nowrap'
  },
  nameCell: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    left: 0,
    zIndex: 9,
    maxWidth: 130,
    minWidth: 130,
    width: 130,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    padding: '4px 0px 4px 9px',
    whiteSpace: 'nowrap'
  },
  nameCellPrint: {
    position: "sticky",
    backgroundColor: "#F8F8F8",
    left: 0,
    zIndex: 9,
    maxWidth: 190,
    minWidth: 190,
    width: 190,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    padding: '4px 0px 4px 9px',
    whiteSpace: 'nowrap'
  },
  pointCell: {
    backgroundColor: "#F8F8F8",
    width: '0.8rem',
    padding: '4px 0px 4px 0px',
  },
   rightIcon: {
    marginLeft: '3px',
    color: 'lightgray',
    fontSize: '1.5em'
  },
  button:{
    minWidth: '100px',
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
},
placementSpanCircle: {
  position: 'absolute', 
  top: '0px',
  left: '0px',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  border: '2px solid #000',
  fontSize: '1.2em',
  color: '#000',
  lineHeight: '30px',
  textAlign: 'center',
}
});


class ScoreTableNoButtons extends React.Component {

  getTableHeaderContent(point, team, classes) {

    if (point.calculationCategory === constants.calculationCategories.closest.value) {
      return <div>
        <span>{point.eventName} {point.isDividingEvent && <MdStars style={{position: 'absolute', color: '#f4c20d', marginLeft: 5, fontSize: '14px'}} />}
        <br/><span style={{fontSize: '11px'}}>närmast {point.closestTo}p </span></span></div>
    }
    else if (point.isDividingEvent === true){
      return <div>{point.eventName} 
      <MdStars style={{position: 'absolute', color: '#f4c20d', marginLeft: 5, fontSize: '14px'}} /><br/>&nbsp;</div>;
    }
   else {
    return <span>{point.eventName}<br/>&nbsp;</span>
    }
  }


  render() {

    const { classes, items, points, onPrintRoute } = this.props;
     if (items !== null){
  return (
    <Flipper flipKey={items}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
          <TableCell className={classes.topLeftCell}>
          <div style={{position: 'absolute'}}>
          <span style={{ fontSize: '8.5px', color: '#000' }}>
          {this.props.infoText}
          </span>
          </div>
          </TableCell>
        {points.map(point => {
          return (
            <TableCell key={point.id} className={classes.tableHeader} style={{paddingTop: onPrintRoute ? 0 : 75}}>
              {this.getTableHeaderContent(point, items, classes)}
            </TableCell>
          );
        })}
          </TableRow>
        </TableHead>
        <TableBody>
        {items.map((item, index) => {
            return (
              <Flipped key={item.id} flipId={item.id}>
              <TableRow key={item.id}>
              <TableCell className={onPrintRoute ? classes.nameCellPrint : classes.nameCell} component="th" scope="row">
                {onPrintRoute ?
                  <div style={{display: 'inline-block', position: 'relative', height: '30px', width: '30px', lineHeight: '30px', textAlign: 'center'}}>
                  {placementIsShown(items, index) &&
                  <div className={classes.placementSpanCircle}>
                  <span>{(item.placement && placementIsShown(items, index)) ? item.placement : <span>&nbsp;</span>}</span>
                  </div>}
                  </div>
                :
                <div style={{display: 'inline-block', backgroundColor: placementIsShown(items, index) ? '#ff5960' : '#F8F8F8', borderRadius: '50%', height: '30px', width: '30px', lineHeight: '30px', textAlign: 'center'}}>
                <span style={{fontSize: '1.2em', color: '#F8F8F8'}}>{(item.placement && placementIsShown(items, index)) ? item.placement : <span>&nbsp;</span>}</span>
                </div>
                }
                <div style={{display: 'inline-block', verticalAlign: onPrintRoute ? 'bottom' : 'middle', marginLeft: onPrintRoute ? '10px' : '6px', lineHeight: '14px'}}>
                <span style={{fontSize: '1em'}}>
                {item.name}
                <br />
                </span>
                <span style={{fontSize: '0.7em'}}>
               Placeringspoäng: {item.totalPlacementpoints}
                </span>
                </div>
                </TableCell>
               {item.points.map(points => {
                return (
                  <TableCell className={classes.pointCell} key={points.id} style={{textAlign:'center'}}>
                  { points.points === null ?
                  <span style={{color: '#000'}}>{points.placementpoints === null ? "-" : points.placementpoints} / -</span>
                : <div
                style={{display: 'inline-block', borderRadius: '5px', height: '100%'}}
                pose={this.props.flashingPointId === points.id ? 'flash' : 'noFlash'}>
                <Button color="primary" disabled style={{whiteSpace: 'nowrap', color: '#000', backgroundColor: 'transparent', maxWidth: '103px'}}>
                &nbsp;&nbsp;{points.placementpoints}<span style={{textTransform: 'none'}}>&nbsp;/&nbsp;{renderPoints(points)}</span>
                </Button>
                </div>
              }
                  </TableCell>
                )})}
              </TableRow>
              </Flipped>
            );
          })}
        </TableBody>
      </Table>
      </Flipper>
  )}
  return (<ProgressIndicator/>)
}
}

ScoreTableNoButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  infoText: PropTypes.any.isRequired,
  onPrintRoute: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ScoreTableNoButtons);
