import { auth } from './firebase';

// Sign Up
export const createUser = (email, password) =>
auth.createUserWithEmailAndPassword(email, password).catch(function(error) {
  // Handle Errors here.
/*   var errorCode = error.code;
  console.log(errorCode);
  var errorMessage = error.message;
  console.log(errorMessage); */
  return;
});

// Sign In
export const signIn = (email, password) =>
 auth.signInWithEmailAndPassword(email, password).catch(function(error) {
  // Handle Errors here.
  /* var errorCode = error.code;
  var errorMessage = error.message; */
});

// Sign out
export const doSignOut = () =>
  auth.signOut();

// Password Reset
export const doPasswordReset = (email) =>
auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
auth.currentUser.updatePassword(password);