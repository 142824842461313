import React from 'react';
import { AuthConsumer } from '../firebase/authContext';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import * as Routes from '../constants/routes';
import { withRouter } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import logo from '../img/logo.svg';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    color: '#FFF'
  },
  title:{
    color: '#FFF',
    marginLeft: 10,
    cursor: 'pointer'
  },
};

class Header extends React.Component {

  state = {
    drawerIsOpen: false,
    auth: true,
  };

  logoutUser(logout) {
    logout();
  };

render() {

  const { classes } = this.props;

  if(this.props.location.pathname.split('/')[1] !== 'play' && this.props.location.pathname.split('/')[1] !== 'print') {
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{backgroundColor: '#ff5960'}}>
      <AuthConsumer>
    {({ authUser, customer }) => (
        <Toolbar>
          <img src={logo} alt="Logo" height="35" width="35" style={{cursor: 'pointer'}} onClick={() => this.props.history.push(Routes.START)} />
          <Typography className={classes.title} variant="h6" color="inherit" noWrap onClick={() => this.props.history.push(Routes.START)}>
            Scoorly&nbsp;&nbsp;|&nbsp;&nbsp;
          </Typography>
          {authUser ? (
            <React.Fragment>
                <Typography style={{color: '#FFF'}}>
                {customer.name}
                </Typography>
           <div className={classes.grow} />
             <IconButton className={classes.button} aria-label="Home" onClick={() => this.props.history.push(Routes.START)}>
          <FaHome style={{ fontSize: 20 }}  />
          </IconButton>
          <IconButton className={classes.button} aria-label="Refresh" onClick={() => this.props.history.push(Routes.ADMIN_EVENTS)}>
        <SettingsIcon />
      </IconButton>
            </React.Fragment>
            ):
            <Button color="inherit" onClick={() => this.props.history.push(Routes.SIGN_IN)}>LOGGA IN</Button>
          }
        </Toolbar>
            )}
            </AuthConsumer>
      </AppBar>
    </div>
  );
        }
        return null;

}}
  export default withRouter(withStyles(styles)(Header));