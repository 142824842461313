import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FaUserPlus } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const styles = theme => ({

  buttonColors: {
    border: '0px solid #509CF5',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    },
  },
  buttonAddPlayer: {
    fontSize: '0.9em',
    color: '#FFF',
    height: 55,
  },
  textField: {
    maxWidth: 400,
    width: 400,
    marginTop: 0,
    marginLeft: 8
  }
});

class ConfirmDialog extends React.Component {

  constructor() {
    super()

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleAddPlayer = this.handleAddPlayer.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.playerNameInput = React.createRef();

    this.state = {
      playerName: '',
      showTextFieldError: false
    };
  }

  handleTextChange(e) {
    this.setState({
      playerName: e.target.value,
      showTextFieldError: false
    });
  };

  handleKeyPress(e) {

    if (e.key === 'Enter') {

      if (this.state.playerName === '') {
        this.setState({ showTextFieldError: true });
        return;
      }

      this.props.onAddPlayer(this.state.playerName, this.props.teamIndex);
      this.setState({ playerName: '' });
    }
  }

  handleAddPlayer(e) {
    if (this.state.playerName === '') {
      this.setState({ showTextFieldError: true });
      return;
    }

    this.props.onAddPlayer(this.state.playerName, this.props.teamIndex);
    this.setState({ playerName: '' });
    setTimeout(() => {
      this.playerNameInput.current.focus();
    }, 100);
  };

  componentDidMount() {
    setTimeout(() => {
      this.playerNameInput.current.focus();
    }, 100);
  }

  render() {

    const { classes } = this.props;
    const { playerName, showTextFieldError } = this.state;

    return (
      <div className={classes.root}>
        <TextField
          error={showTextFieldError}
          id="outlined-name"
          label="Ange namn:"
          className={classes.textField}
          value={playerName}
          onChange={this.handleTextChange}
          onKeyPress={this.handleKeyPress}
          margin="normal"
          variant="outlined"
          inputRef={this.playerNameInput}
        />

        <Button className={classes.buttonColors + ' ' + classes.buttonAddPlayer}
          style={{ marginLeft: '8px' }}
          onClick={this.handleAddPlayer}>
          <Typography variant="h5">&nbsp;
            <FaUserPlus style={{ verticalAlign: 'baseline', paddingTop: 5, color: 'white' }} />
          </Typography>
          &nbsp;&nbsp;Lägg till spelare&nbsp;&nbsp;&nbsp;
        </Button>
      </div>
    );
  }
}
export default withStyles(styles)(ConfirmDialog);
