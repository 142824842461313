export const calculationFormats = {
    point: { value: 'point', description: 'Poäng' },
    time: { value: 'time', description: 'Tid' }
}

export const timeUnits = {
    hours: { value: 'hours' },
    minutes: { value: 'minutes' },
    seconds: { value: 'seconds' },
    hundredths: { value: 'hundredths' },
}

export const timeUnitDirections = {
    left: { value: 'left' },
    right: { value: 'right' }
}

export const calculationCategories = {
    highest: { value: 'highest' },
    closest: { value: 'closest' },
    lowest: { value: 'lowest' }
}

export const placementCalculation = {
    default: { value: 'default' },
    following: { value: 'following' },
}