import React from "react";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import Start from './pages/Start';
import Play from './pages/Play';
import Print from './pages/Print';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import SignInPage from '../src/pages/SignInPage';
import { AuthProvider } from './firebase/authContext';
import ProtectedRoute from './components/ProtectedRoute';
import ActiveTeams from './pages/ActiveTeams';
import AdminEvents from './pages/AdminEvents';
import AdminProfile from './pages/AdminProfile';
import AdminEditTeams from './pages/AdminEditTeams';
import Header from './components/Header';

const primaryTheme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#509CF5',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#ff5960',
      contrastText: '#ffcc00',
    },
  },
  typography: { 
    useNextVariants: true
  }
});
primaryTheme.shadows = [];


const App = () => (
  <Router>
    <MuiThemeProvider theme={primaryTheme}>
    <AuthProvider>
      <React.Fragment>
    <Header/>
    <ProtectedRoute exact path="/" component={Start} />
    <Route path='/SignIn' component={SignInPage} />
    <ProtectedRoute path='/print' component={Print} />
    <ProtectedRoute path='/play/:gameId' component={Play} />
    <ProtectedRoute path='/activeteams' component={ActiveTeams} />
    <ProtectedRoute path='/admin/events' component={AdminEvents} />
    <ProtectedRoute path='/admin/profile' component={AdminProfile} />
    <ProtectedRoute path='/admin/:gameId/edit' component={AdminEditTeams} />
      </React.Fragment>
      </AuthProvider>
    </MuiThemeProvider>
  </Router>
);

export default App;