import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { AiOutlineFileSearch } from "react-icons/ai";
import PrintIcon from '@material-ui/icons/Print';

const styles = theme => ({ 

  button: {
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  cancelButton: {
    color: '#509CF5'
  }
});

class PrintDialog extends React.Component {
  state = {
    open: false,
    checked: true
  };

  handleClose = () => {
    this.props.toggleDialog();
  };

  handleConfirm = (preview) => {
      this.props.confirm(this.state.checked, preview);
  }

  handleToggleCheckbox = () => { 
    this.setState({checked: !this.state.checked});
  }

  render() {

    const { classes } = this.props;
    const { checked } = this.state;

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Skriv ut</DialogTitle>
          <DialogContent>
            <ListItem dense button onClick={this.handleToggleCheckbox}>
              <Checkbox
                checked={checked}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={'Inkludera prispall'} />
            </ListItem>
          </DialogContent>
          <DialogActions>
          <Button className={classes.cancelButton} onClick={() => this.handleClose()} color="primary">
          &nbsp;Avbryt&nbsp;
          </Button>
          <Button className={classes.button} onClick={() => this.handleConfirm(true)} autoFocus>
            &nbsp;<AiOutlineFileSearch style={{ fontSize: 20 }}/>&nbsp;
            </Button>
            <Button className={classes.button} onClick={() => this.handleConfirm(false)} autoFocus>
            &nbsp;<PrintIcon />&nbsp;
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(PrintDialog);
