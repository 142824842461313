import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

firebase.initializeApp({
  apiKey: "AIzaSyAr61ho-ltCvP-WqLBjowrmqSMa_y5OFYc",
  authDomain: "scoorly-prod.firebaseapp.com",
  databaseURL: "https://scoorly-prod.firebaseio.com",
  projectId: "scoorly-prod",
  storageBucket: "scoorly-prod.appspot.com",
  messagingSenderId: "902469166932",
  appId: "1:902469166932:web:1f473456d77811d28acac1"
});


const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export default firebase;

export {
  db,
  auth,
  storage
};