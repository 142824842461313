import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    backgroundColor: 'blue'
  },
  okButton: {
    margin: theme.spacing.unit,
    color: 'white'
  },
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing.unit,
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  cancelButton: {
    color: '#509CF5'
  }
});

class KeypadDialog extends React.Component {

  state = {
      checked: null
  }

  handleToggle(game) {

    this.setState({
      checked: game
    });
  };

  handleClose() {

    this.setState({
      checked: null
    });

    this.props.onClose();
    this.setState({ points: '0' });
  }

  handleSelect() {
    if(this.state.checked !== null){
    this.props.selectGame(this.state.checked);
  }
}

  handleKeypadInput(value) {

    if(value === 'rensa'){
      this.setState({ points: '0' });
      return;
    }

    if(this.state.points.length > 5){
      return;
    }

    var newPoints = '0'
    if(this.state.points === '0'){
        this.setState({ points: value });
        return;
    }

   newPoints = this.state.points + value;
    this.setState({ points: newPoints });
  }

    render() {

      const { classes, games } = this.props;
  
      if (games !== undefined){
    return (
        <Dialog
        open={this.props.activeGamesDialogIsOpen}
        onClose={this.handleClose.bind(this)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: '#F8F8F8',
          }}}
      >
   <DialogTitle id="form-dialog-title">
   <span style={{color: 'gray'}}>Välj lag:
</span>
   </DialogTitle>
        <DialogContent>
        <div className={classes.root}>
        <List>
          {games.map(game => (
            <ListItem
              key={game.gameId}
              role={undefined}
              dense
              button
              onClick={() => this.handleToggle(game)}
              className={classes.listItem}
            >
              <Checkbox
                checked={this.state.checked === game}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={game.teamNames} />
            </ListItem>
          ))}
        </List>
      </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={() => this.handleClose()} color="primary">
            Avbryt
          </Button>
        <Button className={classes.button} onClick={() => this.handleSelect()} size="large">
        Hämta
         </Button>
        </DialogActions>
      </Dialog>
    )
  }
  return (<span></span>)
    }
  }

  export default withStyles(styles)(KeypadDialog);