import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({ 

  button: {
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  cancelButton: {
    color: '#509CF5'
  }
});

class ConfirmDialog extends React.Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.props.toggleDialog();
  };

  handleConfirm = () => {
      this.props.confirm();
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button className={classes.cancelButton} onClick={() => this.handleClose()} color="primary">
            Avbryt
          </Button>
            <Button className={classes.button} onClick={this.handleConfirm} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(ConfirmDialog);
